import { useContext } from "react";
import { Table, Steps } from "antd";
import { HiCheck, HiX } from "react-icons/hi";
import { FiLoader } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { DEMOsetJ } from "../../../demoSlice";
import { ThemeContext } from "../../ThemeProvider";
const { Step } = Steps;

const TestStatus = ({ value }) => {
  return (
    <div className="text-lg">
      {value === "success" ? (
        <HiCheck className="text-green-700" />
      ) : value === "failure" ? (
        <HiX className="text-red-700" />
      ) : (
        value === "current" && <FiLoader className="animate-spin spin-slow" />
      )}
    </div>
  );
};

const MemoryAnchoringDEMOPanel = () => {
  const { t } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const j = useSelector((state) => state.demo.j);
  const overview = useSelector((state) => state.demo.overview);

  const getCurrentJIndex = () => {
    if (j === 0) {
      return 0;
    } else if (j === 2) {
      return 1;
    } else if (j === 7) {
      return 2;
    } else if (j === 30) {
      return 3;
    } else return 3;
  };

  const getColumns = () => {
    return [
      {
        title: "Concept",
        dataIndex: "concept",
        key: "concept",
      },
      {
        title: t("learning-date"),
        dataIndex: "acquisition",
        key: "acquisition",
      },
      {
        title: t("1st") + " test",
        dataIndex: "1",
        key: "1",
        render: (value) => <TestStatus value={value} />,
      },
      {
        title: t("2nd") + " test",
        dataIndex: "2",
        key: "2",
        render: (value) => <TestStatus value={value} />,
      },
      {
        title: t("3rd") + " test",
        dataIndex: "3",
        key: "3",
        render: (value) => <TestStatus value={value} />,
      },
      {
        title: t("4th") + " test",
        dataIndex: "4",
        key: "4",
        render: (value) => <TestStatus value={value} />,
      },
    ];
  };
  return (
    <div
      className="flex flex-col gap-2 bg-gray-100 m-4 rounded-lg p-2"
      style={{ maxWidth: "560px" }}
    >
      <div className="flex flex-col gap-2 rounded-t-md bg-white p-2">
        <div className="text-semibold text-base">Demo config panel</div>
        <div>{t("demo-description")}</div>
      </div>
      <div className="flex items-center bg-white">
        <Table
          className="w-full"
          columns={getColumns()}
          dataSource={overview}
          pagination={false}
        />
      </div>
      <div className="flex items-center rounded-b-sm bg-white relative h-16">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Steps size="small" progressDot current={getCurrentJIndex()} className="w-full">
            <Step
              title="J0"
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(0))}
            />
            <Step
              title="J+2"
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(2))}
            />
            <Step
              title="J+7"
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(7))}
            />
            <Step
              title="J+30"
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(30))}
            />
          </Steps>
        </div>
      </div>
    </div>
  );
};
export default MemoryAnchoringDEMOPanel;
