import HintsItem from "./HintsItem";
import { useSelector, useDispatch } from "react-redux";
import { MdFace } from "react-icons/md";
import { useContext, useState } from "react";
import { ThemeContext } from "../../../../../ThemeProvider";
import {
  XnewChatMessage,
  XresetBobSuggestions,
  XsetBobInputValue,
} from "../../../../../../appSlice";

const HintsV2 = () => {
  const dispatch = useDispatch();
  const nb_auto_suggestions = useSelector(
    (state) => state.app.general.nb_auto_suggestions
  );
  const bob_suggestions = useSelector(
    (state) => state.app.chat.bob_suggestions
  );
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const bob_input_value = useSelector(
    (state) => state.app.chat.bob_input_value
  );

  const intention = useSelector((state) => state.app.chat.intention);

  const { t } = useContext(ThemeContext);

  const [hovered, setHovered] = useState(false);

  return (
    <>
      {(bob_suggestions.length > 0 || intention) && (
        <div
          className=" absolute bottom-0 w-full p-2"
          style={{
            maxHeight: "350px",
          }}
        >
          <div
            className="overflow-auto bg-white rounded-md border"
            style={{
              borderColor: themeColors.primary.base,
              boxShadow:
                "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
            }}
          >
            <div
              className="divide-y divide-gray-100 py-2 "
              style={{ paddingLeft: 7, paddingRight: 7 }}
            >
              {bob_suggestions.length > 0 && (
                <div className="flex flex-col mb-2">
                  {bob_suggestions
                    ?.slice(0, nb_auto_suggestions)
                    .map((hint, i) => (
                      <HintsItem hint={hint} index={i} />
                    ))}
                  <div className="m-auto text-xs font-medium text-gray-400">
                  {t("or")}
                  </div>
                </div>
              )}
              {intention && (
                <div className="flex flex-col p-2">
                  <div className="flex ">
                    <div className="text-gray-400 font-medium mr-1">
                      {t("bot-name")}:
                    </div>
                    <div>
                      <div>{intention.chat.text}</div>
                      {!(
                        intention.chat.text.includes("Je ne suis pas sûr") ||
                        intention.chat.text.includes(
                          "Sorry for not providing a responds"
                        )
                      ) && <div>{t("tips-ask-bob")}</div>}
                    </div>
                  </div>
                  <div className="m-auto text-xs font-medium text-gray-400">
                    {t("or")}
                  </div>
                </div>
              )}
              <div
                style={
                  hovered
                    ? {
                        backgroundColor: themeColors.primary.base,
                        border: "1px solid",
                      }
                    : {
                        border: "1px solid",
                        borderColor: themeColors.primary.base,
                      }
                }
                className={`rounded py-2 px-1 hover:text-white flex items-center`}
                onMouseEnter={() => {
                  setHovered(true);
                }}
                onMouseLeave={() => {
                  setHovered(false);
                }}
              >
                <div
                  className="flex-1 flex items-center cursor-pointer font-medium"
                  style={{
                    color: hovered ? "#FFFFFF" : themeColors.primary.base,
                  }}
                  onClick={() => {
                    dispatch(
                      XnewChatMessage({
                        chat: {
                          text: t("ask-teacher-directly"),
                          type: "chat",
                          original_question: t("ask-teacher-directly"),
                        },
                        timestamp: new Date().getTime(),
                        user: {
                          userID: "1",
                        },
                      })
                    );
                    dispatch(
                      XnewChatMessage({
                        answer: null,
                        chapterID: null,
                        chat: {
                          text: null,
                          type: "question_edit",
                          original_question: bob_input_value,
                        },
                        conversationID: null,
                        courseID: null,
                        related_questions: null,
                        timestamp: new Date(),
                        user: {
                          userID: "0",
                          username: null,
                        },
                      })
                    );
                    dispatch(XresetBobSuggestions());
                    dispatch(XsetBobInputValue(""));
                  }}
                >
                  <div className="text-lg pr-2">
                    <MdFace />
                  </div>
                  {t("ask-teacher-directly")}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HintsV2;
