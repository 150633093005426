import { Progress } from "antd";
import { useContext, useEffect, useState } from "react";
import { HiOutlineFastForward } from "react-icons/hi";
import { TIMER } from "../../../constants/constants";
import { ThemeContext } from "../../ThemeProvider";
import MarkDownRender from "../../MarkDown/MarkDownRender";
import PaneContent from "../components/PaneContent";
import { useSelector, useDispatch } from "react-redux";
import {
  DEMOsetCurrentQuestionIndex,
  DEMOsetQuestions,
  DEMOsetStage,
} from "../../../demoSlice";

const Ping = () => {
  return (
    <div className="absolute -top-1 -right-1">
      <div class="relative flex h-3 w-3">
        <div class="animate-ping inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
        <div class="inline-flex absolute rounded-full h-3 w-3 bg-red-500" />
      </div>
    </div>
  );
};

const MemoryAnchoringContainer = () => {
  const { t } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const demo_client = useSelector((state) => state.app.general.demo_client);
  const questions = useSelector((state) => state.demo.questions);
  const stage = useSelector((state) => state.demo.stage);
  const currentQuestionIndex = useSelector(
    (state) => state.demo.current_question_index
  );
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );

  const [timeLeft, setTimeLeft] = useState(TIMER.TIMER_VALUE * 100);
  const [hovered, setHovered] = useState(false);
  const [hoveredNO, setHoveredNO] = useState(false);
  const [hoveredYES, setHoveredYES] = useState(false);

  useEffect(() => {
    dispatch(DEMOsetQuestions(demo_client));
  }, []);

  useEffect(() => {
    if (stage === 2) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 100);
      if (!timeLeft) {
        dispatch(DEMOsetStage(3));
      }
      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }
  }, [stage, timeLeft]);

  useEffect(() => {
    if (currentQuestionIndex > 0) {
      if (currentQuestionIndex < questions.length) {
        dispatch(DEMOsetStage(2));
      } else {
        dispatch(DEMOsetStage(4));
      }
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    setTimeLeft(TIMER.TIMER_VALUE * 100);
  }, [stage]);

  return (
    <div className="h-full w-full flex flex-col items-center flex-1 justify-center overflow-y-auto py-6 ">
      {stage === 1 && questions.length > 0 ? (
        <div className="flex flex-col items-center justify-center">
          <div className="m-3 mb-6">{t("memory-anchoring-3-questions")}</div>
          <div
            style={{
              backgroundColor: hovered
                ? themeColors.primary.base
                : themeColors.primary.base,
            }}
            onMouseLeave={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
            className="flex items-center justify-center rounded h-9 text-white px-6 cursor-pointer"
            onClick={() => dispatch(DEMOsetStage(2))}
          >
            {t("memory-anchoring-lets-go")}
          </div>
        </div>
      ) : (
        stage === 1 && (
          <div className="h-80 flex flex-col items-center justify-center">
            <div className="mb-3 font-bold ">
              {t("welcome-knowledge-anchoring-page")}
            </div>
            <div className="mb-3 px-6 text-center">
              {t("you-dont-have-knowledge-anchoring-for-today")}
            </div>
            <div className="px-6 text-center">
              {t("memory-anchoring-come-again-tomorow")}
            </div>
          </div>
        )
      )}
      {stage === 2 && (
        <div className="flex flex-col flex-1 justify-center items-center">
          <div className="m-3 mb-0 text-gray-500 text-3xs">
            {questions[currentQuestionIndex]?.context}
          </div>
          <div className="mx-10 text-base font-medium text-center">
            {questions[currentQuestionIndex]?.question}
          </div>
          <div className="mt-6 w-5/6 flex items-center">
            <Progress
              showInfo={false}
              size="small"
              percent={timeLeft / TIMER.TIMER_VALUE}
              strokeColor={themeColors.primary.base}
            />
            <div
              style={{
                color: themeColors.primary?.base,
              }}
              className={`text-lg ml-3 mt-0.5 cursor-pointer`}
              onClick={() => dispatch(DEMOsetStage(3))}
            >
              <HiOutlineFastForward />
            </div>
          </div>
        </div>
      )}
      {stage === 3 && (
        <div className="flex flex-col flex-1 justify-center items-center">
          <div className="m-3 mt-14 sm:mt-3 mb-0 text-gray-500 text-3xs">
            {questions[currentQuestionIndex]?.context}
          </div>
          <div className="mx-10 text-base font-medium text-center">
            {questions[currentQuestionIndex]?.question}
          </div>
          <div className="mt-8 w-5/6 flex flex-col">
            <MarkDownRender
              source={questions[currentQuestionIndex]?.answer || ""}
            />
          </div>
          <div className="flex space-x-3 my-6 mt-6">
            <div
              style={{
                color: hoveredNO
                  ? themeColors.primary.base
                  : themeColors.primary.base,
                borderColor: hoveredNO
                  ? themeColors.primary.base
                  : themeColors.primary.base,
              }}
              className={`relative flex items-center justify-center rounded h-9 bg-white px-3 cursor-pointer border-2 font-medium`}
              onMouseEnter={() => setHoveredNO(true)}
              onMouseLeave={() => setHoveredNO(false)}
              onClick={() => {
                dispatch(DEMOsetCurrentQuestionIndex());
              }}
            >
              {!questions[currentQuestionIndex]?.expected_choice && <Ping />}
              {t("memory-anchoring-user-answers-I-am-learning-it")}
            </div>
            <div
              style={{
                backgroundColor: hoveredYES
                  ? themeColors.primary.base
                  : themeColors.primary.base,
              }}
              onMouseEnter={() => setHoveredYES(true)}
              onMouseLeave={() => setHoveredYES(false)}
              className={`relative flex items-center justify-center rounded h-9 text-white px-3 cursor-pointer font-medium`}
              onClick={() => {
                dispatch(DEMOsetCurrentQuestionIndex());
              }}
            >
              {questions[currentQuestionIndex]?.expected_choice && <Ping />}
              {t("memory-anchoring-user-answers-I-knew-it")}
            </div>
          </div>
        </div>
      )}
      {stage === 4 && (
        <div
          className="flex flex-col flex-1 h-full justify-center items-center text-gray-500"
          onClick={() => {}}
        >
          <div className="mb-3 font-bold ">
            {t("memory-anchoring-congratulations")}
          </div>
          <div className="mb-3 px-6 text-center">
            {t("memory-anchoring-come-again")}
          </div>
          <div className="px-6 text-center">
            {t("memory-anchoring-come-again-content")}
          </div>
        </div>
      )}
    </div>
  );
};

const MemoryAnchoringPaneDEMO = () => {
  return (
    <>
      <PaneContent>
        <MemoryAnchoringContainer></MemoryAnchoringContainer>
      </PaneContent>
    </>
  );
};

export default MemoryAnchoringPaneDEMO;
