import { forwardRef, useEffect, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ChatMessageItemBob from "./components/ChatMessageItemBob";
import ChatMessageItemUser from "./components/ChatMessageItemUser";
import ChatMessageItemAnswer from "./components/ChatMessageItemAnswer";
import RelatedQuestions from "./components/RelatedQuestions";
import ChatOfferAskTeacher from "./components/ChatOfferAskTeacher";
import ChatMessageTeacherQuestionEdit from "./components/ChatMessageTeacherQuestionEdit";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { XgetBobChatHistory } from "../../../../../appSlice";
import { Spin } from "antd";
import Lottie from "react-lottie";
import animationData from "../../../../../assets/chat-typing-indicator.json";

const ChatMessageItem = forwardRef(
  (
    { dataArrayIndex, user, chat, related_questions, answer, focusOnChatInput, timestamp, originalTimestamp },
    olderMessageScrollRef
  ) => {
    if (chat.type === "question_edit") {
      return (
        <ChatMessageTeacherQuestionEdit
          ref={olderMessageScrollRef}
          dataArrayIndex={dataArrayIndex}
          question={chat.original_question}
          focusOnChatInput={focusOnChatInput}
          originalTimestamp={originalTimestamp}
        />
      );
    }
    if (chat.type === "answer") {
      return (
        <ChatMessageItemAnswer
          associated_question={chat.associated_question}
          original_question={chat.original_question}
          answer={answer}
          dataArrayIndex={dataArrayIndex}
          ref={olderMessageScrollRef}
        />
      );
    }
    if (chat.type === "related-questions") {
      return (
        <RelatedQuestions
          ref={olderMessageScrollRef}
          dataArrayIndex={dataArrayIndex}
          related_questions={related_questions}
          original_question={chat.original_question}
        />
      );
    }
    if (user && parseInt(user.userID) > 0) {
      return (
        <ChatMessageItemUser
          chat={chat}
          ref={olderMessageScrollRef}
          dataArrayIndex={dataArrayIndex}
        />
      );
    }
    if (user.userID === "0") {
      return (
        <ChatMessageItemBob
          chat={chat}
          ref={olderMessageScrollRef}
          dataArrayIndex={dataArrayIndex}
        />
      );
    }
    if (user.userID === "-1") {
      // BOB CAN'T ANSWER
      // MESSAGE FOR USER
      if (chat.text) {
        return (
          <ChatMessageItemBob
            chat={chat}
            ref={olderMessageScrollRef}
            dataArrayIndex={dataArrayIndex}
          />
        );
      }
      // Offer to ask teacher question
      if (!chat.text) {
        return (
          <ChatOfferAskTeacher
            ref={olderMessageScrollRef}
            dataArrayIndex={dataArrayIndex}
            originalQuestion={chat.original_question}
            timestamp={timestamp}
            focusOnChatInput={focusOnChatInput}
          />
        );
      }
    }

    return <div></div>;
  }
);

const TypingMessage = () => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="flex flex-col items-start w-9/12">
      <div
        className="flex flex-col rounded-tl-sm rounded-lg pt-2"
        style={{
          backgroundColor: themeColors.answer_background.base,
          width: "fit-content",
        }}
      >
        <Lottie
          options={defaultOptions}
          height={35}
          width={70}
          isStopped={false}
          isPaused={false}
        />
      </div>
    </div>
  );
};

const ChatMessages = ({ focusOnChatInput }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.app.chat.loading);
  const chat_data_array = useSelector(
    (state) => state.app.chat.chat_data_array
  );
  const older_message_index = useSelector(
    (state) => state.app.chat.older_message_index
  );

  const bottomScrollRef = useRef(null);
  const olderMessageScrollRef = useRef(null);
  const scrollToBottom = () => {
    bottomScrollRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const [lastMessageDate, setLastMessageDate] = useState(new Date(2020, 1, 1));
  useEffect(() => {
    if (
      chat_data_array[chat_data_array.length - 1].timestamp > lastMessageDate
    ) {
      console.log("scroll to bottom new message");
      scrollToBottom();
      setLastMessageDate(chat_data_array[chat_data_array.length - 1].timestamp);
    } else if (loading) {
      console.log("scroll to older message");
      olderMessageScrollRef.current?.scrollIntoView(true);
      setTimeout(() => {
        olderMessageScrollRef.current?.scrollIntoView(true);
      }, 100);
    }
  }, [chat_data_array]);

  useEffect(() => {
    console.log("scroll to bottom init");
    setTimeout(() => {
      bottomScrollRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }, 500);
  }, []);

  const handleOnScroll = (e) => {
    if (e.target.scrollTop === 0 && !loading) {
      dispatch(XgetBobChatHistory());
    }
  };
  
  return (
    <div
      id="chatMessages"
      className="invisible-scrollbar absolute bottom-0 prose-xs px-2 pl-2 pt-5 my-2 max-h-full w-full overflow-auto"
      onScroll={handleOnScroll}
    >
      {loading && (
        <div className="flex items-center">
          <div className="m-auto">
            <Spin />
          </div>
        </div>
      )}
      <TransitionGroup className="flex flex-col gap-2">
        {chat_data_array?.map((item, i) => (
          <CSSTransition key={i} timeout={500} classNames="chat">
            <ChatMessageItem
              ref={
                i === chat_data_array.length - older_message_index
                  ? olderMessageScrollRef
                  : null
              }
              {...item}
              key={i}
              dataArrayIndex={i}
              focusOnChatInput={focusOnChatInput}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      {parseInt(chat_data_array[chat_data_array.length - 1].user.userID) ===
        1 && <TypingMessage />}
      <div ref={bottomScrollRef} />
    </div>
  );
};

export default ChatMessages;
