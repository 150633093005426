import { useEffect } from "react";
import { PANES } from "./constants/constants";
import "typeface-dm-sans";
import "./App.less";
import ConversationStarter from "./components/ConversationStarter/ConversationStarter";
import Pane from "./components/Pane/Pane";
import MemoryAnchoringDEMOPanel from "./components/Pane/MemoryAnchoringPane/MemoryAnchoringDEMOPanel";
import SideBar from "./components/SideBar/SideBar";
import { IDLE_TIMEOUT, LAYOUT } from "./constants/constants";
import { useSelector, useDispatch } from "react-redux";
import {
  XsetExternalIDs,
  XsetTestMode,
  XsetDevMode,
  XsetIframeType,
  XsetDemoClient,
  XsetBobPosition,
  XsetLocale,
  XsetExpandedPane,
  XpostLogEvent,
  XsetConversationStarterVisible,
  XgetUserStudentMetrics,
  initSocket,
  closeSocket,
  XgetKnowledgeAnchoringUserStatus,
  XBobClicked,
} from "./appSlice";

const App = () => {
  const dispatch = useDispatch();
  const iFrame_type = useSelector((state) => state.app.general.iFrame_type);
  const sidebar_position = useSelector(
    (state) => state.app.general.sidebar_position
  );
  const bob_position = useSelector((state) => state.app.general.bob_position);
  const knowledge_anchoring_visible = useSelector(
    (state) => state.app.general.knowledge_anchoring_visible
  );
  const open_on_mobile = useSelector(
    (state) => state.app.general.open_on_mobile
  );
  const open_on_mobile_only = useSelector(
    (state) => state.app.general.open_on_mobile_only
  );
  const active_pane = useSelector((state) => state.app.general.active_pane);
  const demo_client = useSelector((state) => state.app.general.demo_client);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(XgetUserStudentMetrics());
    }, 60000);
    dispatch(XgetUserStudentMetrics());
    dispatch(initSocket());
    return () => {
      clearInterval(interval);
      dispatch(closeSocket());
    };
  }, []);

  useEffect(() => {
    if (knowledge_anchoring_visible) {
      dispatch(XgetKnowledgeAnchoringUserStatus());
    }
  }, [knowledge_anchoring_visible]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(XsetConversationStarterVisible(true));
      setTimeout(() => {
        dispatch(XsetConversationStarterVisible(false));
      }, IDLE_TIMEOUT.IDLE_DISAPPEAR_TIMEOUT);
    }, IDLE_TIMEOUT.IDLE_TIMEOUT);
  }, []);

  const isSideBarDisplayed = () => {
    if (open_on_mobile_only && iFrame_type === "responsive") {
      return false;
    } else return true;
  };

  const isDemoPanelDisplayed = () => {
    if (active_pane === PANES.MEMORY_ANCHORING && demo_client) {
      return true;
    } else return false;
  };

  return (
    <div
      onClick={() => dispatch(XBobClicked())}
      className={`flex font-DMSans text-gray-700 text-2xs ${
        bob_position === LAYOUT.BOB_POSITION.LEFT &&
        !open_on_mobile &&
        " p-2 pr-5 "
      }
      ${
        bob_position === LAYOUT.BOB_POSITION.RIGHT &&
        !open_on_mobile &&
        " p-2 pl-5 "
      } ${
        iFrame_type === "responsive"
          ? " w-full md:w-3/4 h-full m-auto"
          : " ml-auto"
      }
    ${bob_position === LAYOUT.BOB_POSITION.LEFT ? "flex-row-reverse " : ""} ${
        sidebar_position === LAYOUT.SIDEBAR_POSITION.BOTTOM ? "items-end " : ""
      } ${
        sidebar_position === LAYOUT.SIDEBAR_POSITION.CENTER
          ? "items-center "
          : ""
      }
    `}
    >
      {isDemoPanelDisplayed() && <MemoryAnchoringDEMOPanel />}
      <ConversationStarter />
      <Pane />
      {isSideBarDisplayed() && <SideBar />}
    </div>
  );
};

const AppDataWrapper = () => {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);

  const dispatch = useDispatch();
  const theme_loaded = useSelector((state) => state.app.theme.loaded);
  const external_client_id = useSelector(
    (state) => state.app.id.external_client
  );
  const open_on_mobile_only = useSelector(
    (state) => state.app.general.open_on_mobile_only
  );

  useEffect(() => {
    if (
      params.get("course_key") &&
      params.get("corpus_key") &&
      params.get("chapter_key") &&
      params.get("user_key")
    ) {
      dispatch(
        XsetExternalIDs({
          user: params.get("user_key"),
          course: params.get("course_key"),
          corpus: params.get("corpus_key"),
          chapter: params.get("chapter_key"),
        })
      );

      if (params.get("test_mode") === "on" || params.get("testmode") === "on") {
        dispatch(XsetTestMode(true));
      }
      if (params.get("dev_mode") === "on" || params.get("devmode") === "on") {
        dispatch(XsetDevMode(true));
      }
      if (params.get("iframetype")) {
        dispatch(XsetIframeType(params.get("iframetype")));
        dispatch(XsetExpandedPane(true));
      }
      if (params.get("demo_client") && params.get("demo_client") !== "false") {
        dispatch(XsetDemoClient(params.get("demo_client")));
      }
      if (params.get("layout")) {
        dispatch(XsetBobPosition(params.get("layout")));
      }
      if (params.get("locale")) {
        dispatch(XsetLocale(params.get("locale")));
      }
    }
  }, []);

  if (theme_loaded) {
    if (
      external_client_id === "469785f61e5c7e" &&
      open_on_mobile_only &&
      process.env.REACT_APP_STAGE === "prod"
    ) {
      return "";
    }
    dispatch(
      XpostLogEvent({
        event_type: "bobLoaded",
        input: null,
        origin: null,
        from: null,
        userInput: null,
      })
    );
    return <App />;
  } else {
    return "";
  }
};

export default AppDataWrapper;
