import PaneContent from "../components/PaneContent";
import InfoCard from "./InfoCard";
import { useSelector } from "react-redux";

const InfoPane = () => {
  const data_info = [
    {
      category: "Présentation",
      cards: [
        {
          question: "A quoi sert Alan ?",
          description:
            "Alan vous accompagne dans vos apprentissages et vous aide dans le déroulement de votre formation en répondant à vos questions.",
        },
        {
          question: "Qui est Alan ? Qui me répond ?",
          description:
            "Alan est un assistant virtuel prévu pour répondre à vos questions.",
        },
        {
          question: "Où puis-je trouver Alan ?",
          description:
            "Alan s'affiche sous la forme d'une petite barre sur la droite de votre écran. Il est disponible sur votre espace inscrit/CNED360 ainsi que sur votre espace de formation dans vos cours de SVT et d'Histoire-Géographie.",
        },
        {
          question: "Comment fonctionne Alan ?",
          description:
            "Alan est un robot qui a appris et est encore en train d'apprendre le contenu de vos cours et les informations liées au déroulement de votre formation. Les contenus de vos cours et les informations concernant le déroulement de votre formation ont été intégrés, et les questions que vous posez qui ne sont pas encore maîtrisées par Alan servent à enrichir sa base de connaissances.",
        },
      ],
    },
    {
      category: "Fonctionnement",
      cards: [
        {
          question: "Comment puis-je utiliser Alan ?",
          description:
            "Il vous faut formuler une question précise en évitant le langage SMS, les sigles et les acronymes. Des questions vous seront suggérées en fonction des mots clés employés dans votre question : choisissez la question correspondant à votre demande.  Lorsque vous posez une question à laquelle Alan ne sait pas répondre, ce dernier vous propose d'envoyer votre question à un professeur du CNED. La réponse à votre question vous sera transmise par Alan une fois que le professeur l'aura traitée, dans l'onglet 'réponse des professeurs'.",
        },
        {
          question: "Quelles questions je peux poser à Alan ?",
          description:
            "Vous pouvez poser des questions relatives au cours que vous êtes en train de consulter. Vos questions concernant le déroulement de votre formation peuvent être posées depuis votre espace inscrit/CNED360.",
        },
        {
          question: "Alan est-il toujours disponible ?",
          description:
            "Oui, Alan est disponible 24h/24 depuis CNED360 ou depuis vos cours de SVT et d'Histoire-Géographie",
        },
        {
          question:
            "Pourquoi Alan ne répond pas aux mêmes questions selon où je me trouve dans mon espace de travail ?",
          description:
            "Alan est conçu pour répondre à différentes thématiques selon où vous vous situez sur le site. Dans votre espace inscrit/CNED360, Alan répondra à vos questions concernant le déroulement de votre formation. Dans votre cours de SVT, il répondra à vos questions concernant la SVT. Et dans votre cours d'Histoire-Géographie, il répondra à vos questions concernant l'Hsitoire-Géographie.",
        },
      ],
    },
    {
      category: "Données",
      cards: [
        {
          question: "Comment sont gérées mes données ?",
          description:
            "Le CNED accorde une grande importance à la qualité de ses enseignements et des services qui vous sont fournis. A cette fin, il réalise régulièrement des expérimentations de produits ou services afin de les intégrer ensuite dans ces formations si les tests sont concluants. Le CNED étant soucieux de la façon dont sont traitées vos données, vous êtes informé que, comme pour tout service en ligne, l’utilisation de Alan implique le traitement des données à caractère personnel vous concernant saisies lors de votre enregistrement sur la plateforme et liées à vos activités. Elles sont traitées uniquement dans les conditions fixées par le CNED  par son prestataire, la société The AI Institute. Ces données ne sont traitées qu’à fin de réalisation de l’expérimentation et pour la durée de celle-ci. Aucune utilisation commercile de vos données n'est réalisée, ni par le CNED, ni par AI Institute. Elles sont hébergées en Union Européenne (France et Belgique). Vous disposez d’un droit d’accès, rectification, suppression et limitation de vos données que vous pouvez exercer via l'adresse DPO@ac-cned.fr",
        },
      ],
    },
    {
      category: "Navigateur",
      cards: [
        {
          question: "Quels sont les navigateurs compatibles avec Alan ?",
          description: "Tous sauf Internet Explorer",
        },
      ],
    },

    {
      category: "Erreur",
      cards: [
        {
          question:
            "J'ai des problèmes d'affichage avec Alan, que dois-je faire ?",
          description:
            "Alan est en version bêta, il peut subsister quelques bugs. Un simple rafraichissement de page suffit généralement à résoudre le problème. Si ce dernier persiste, vous pouvez communiquer votre problème depuis un autre cours sur lequel Alan s'affiche, en précisant vos coordonnées.",
        },
        {
          question:
            "J'ai posé une question à Alan et je n'ai toujours pas de réponse",
          description:
            "Êtes vous allé voir vos notifications dans Alan ? La réponse apparaitra dans l'onglet 'réponse des professeurs' et non dans la partie que vous utilisez habituellement.",
        },
      ],
    },
    {
      category: "Professeurs",
      cards: [
        {
          question: "Est-ce que j'ai toujours accès à mes professeurs ?",
          description:
            "Alan ne remplace pas vos professeurs. Vous avez toujours la possibilité de contacter vos professeurs tuteurs disciplinaires et vos professeurs principaux qui répondront à vos demandes personnalisées. Alan répond à des questions plus générales.",
        },
      ],
    },
  ];
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  return (
    <PaneContent>
      <div className="flex flex-col gap-3 py-4 items-center">
        <div
          className={`${expanded_pane ? "text-lg" : "text-base"} font-bold`}
          style={{ color: themeColors.primary.base }}
        >
          Salut moi c'est Alan...
        </div>
        {data_info.map((category) => (
          <div className="flex flex-col gap-3 w-full">
            <div className="p-3 pb-0 text-lg font-medium">
              {category.category}
            </div>
            {category.cards.map((card, index) => (
              <InfoCard card={card} key={index} />
            ))}
          </div>
        ))}
      </div>
    </PaneContent>
  );
};

export default InfoPane;
