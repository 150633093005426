import { createSlice } from "@reduxjs/toolkit";

const demo_q = {
  0: {
    "3WA": [],
    ASU: [],
  },
  2: {
    "3WA": [
      {
        context: "HTML",
        question: "Comment inclure une feuille de style en HTML ?",
        answer:
          "Il y a deux méthodes principales pour placer une feuille de style. La première consiste à placer la feuille de style dans l'entête de document. La seconde consiste à placer la feuille de style dans un fichier séparé, et à y faire référence dans l'entête du document.",
        expected_choice: true,
      },
    ],
    ASU: [
      {
        context: "Series",
        question: "What are absolute maximum and minimum values?",
        answer:
          "An absolute maximum point is a point where the function obtains its greatest possible value. Similarly, an absolute minimum point is a point where the function obtains its least possible value.",
        expected_choice: true,
      },
    ],
  },
  7: {
    "3WA": [
      {
        context: "HTML",
        question: "Comment mettre des ancres en HTML ?",
        answer:
          "Créer une ancre est facile : il suffit d'attribuer à l'élément vers lequel on veut pouvoir pointer un identifiant (avec l'attribut HTML id ) et d'y associer un lien débutant par le caractère dièse # , suivi du nom de cet identifiant.",
        expected_choice: true,
      },
      {
        context: "CSS",
        question: "Comment définir une classe CSS ?",
        answer:
          "Définir une classe revient à donner un nom de style à un élément HTML et à préciser les propriétés de style pour ce nom dans notre feuille de style. Dans notre feuille de style, nous allons définir cette classe ainsi : . nom_de_ma_classe { liste des propriétés ici }.",
        expected_choice: true,
      },
    ],
    ASU: [
      {
        context: "Series",
        question: "How to define convergence",
        answer:
          "Convergence is the property (exhibited by certain infinite series and functions) of approaching a limit more and more closely as an argument (variable) of the function increases or decreases or as the number of terms of the series increases.",
        expected_choice: true,
      },
      {
        context: "Calculus",
        question: "What is antiderivative?",
        answer:
          "Antiderivatives are the opposite of derivatives. An antiderivative is a function that reverses what the derivative does. One function has many antiderivatives, but they all take the form of a function plus an arbitrary constant. Antiderivatives are a key part of indefinite integrals.",
        expected_choice: true,
      },
    ],
  },
  30: {
    "3WA": [
      {
        context: "HTML",
        question:
          "Quels sont les éléments HTML permettant de prendre en charge l'affichage de données textuelles sur une page ?",
        answer:
          "Le contenu HTML textuel permet d'organiser des blocs ou des sections de contenu entre la balise ouvrante <body> et la balise fermante </body> . Ces éléments sont cruciaux pour l'accessibilité et le référencement car ils permettent d'identifier le sens du contenu.",
        expected_choice: true,
      },
      {
        context: "CSS",
        question: "Où placer le code CSS dans HTML ?",
        answer:
          "Il est possible d'écrire du code CSS dans l'entete d'un document HTML, dans la balise <head>. Il suffit pour cela de l'encadrer par une balise <style> ayant pour attribut type='text/css'.",

        expected_choice: true,
      },
      {
        context: "Balises",
        question: "Quel est le rôle des balises hn ?",
        answer:
          "En référencement naturel, Hn décrit une balise utilisée pour le titrage et sous-titrage d'un contenu web (en complément de la balise Title). Elle se décline sous la forme h1, h2, h3, etc., jusqu'à h6, et entoure les différents titres et sous-titres d'un document en les hiérarchisant sur plusieurs niveaux (6 au total).",
        expected_choice: false,
      },
    ],
    ASU: [
      {
        context: "Series",
        question: "What is the Fourier Series",
        answer:
          "Fourier series is an infinite series used to solve special types of differential equations. It consists of an infinite sum of sines and cosines, and because it is periodic (i.e., its values repeat over fixed intervals), it is a useful tool in analyzing periodic functions. Though investigated by Leonhard Euler, among others, the idea was named for Joseph Fourier, who fully explored its consequences, including important applications in engineering, particularly in heat conduction.",
        expected_choice: true,
      },
      {
        context: "Calculus",
        question: "What is a differentiable function?",
        answer:
          "In mathematics, a differentiable function of one real variable is a function whose derivative exists at each point in its domain. In other words, the graph of a differentiable function has a non-vertical tangent line at each interior point in its domain. A differentiable function is smooth (the function is locally well approximated as a linear function at each interior point) and does not contain any break, angle, or cusp.",
        expected_choice: true,
      },
      {
        context: "Statistics",
        question: "What is Normal distribution?",
        answer:
          "Normal distribution, also known as the Gaussian distribution, is a probability distribution that is symmetric about the mean, showing that data near the mean are more frequent in occurrence than data far from the mean. In graph form, normal distribution will appear as a bell curve.",
        expected_choice: false,
      },
    ],
  },
};

const client_concepts = {
  ASU: ["Series", "Calculus", "Statistics"],
  "3WA": ["HTML", "CSS", "Balises"],
};

export const demoSlice = createSlice({
  name: "demo",
  initialState: {
    client: "ASU",
    questions: demo_q[0]["ASU"],
    stage: 1,
    current_question_index: 0,
    j: 0,
    overview: [
      {
        key: "1",
        concept: client_concepts["ASU"][0],
        acquisition: "01/01/2022 (J0)",
        1: "",
        2: "",
        3: "",
      },
      {
        key: "2",
        concept: client_concepts["ASU"][1],
        acquisition: "",
        1: "",
        2: "",
        3: "",
      },
      {
        key: "3",
        concept: client_concepts["ASU"][2],
        acquisition: "",
        1: "",
        2: "",
        3: "",
      },
    ],
  },
  reducers: {
    DEMOsetQuestions: (state, action) => {
      if (demo_q[0][action.payload]) {
        state.client = action.payload;
        state.overview.forEach((element, index) => {
          element.concept = client_concepts[action.payload][index];
        });
        state.questions = demo_q[0][action.payload];
      }
    },
    DEMOsetStage: (state, action) => {
      state.stage = action.payload;
      if (action.payload === 2) {
        if (state.j === 2) {
          state.overview[state.current_question_index][1] = "current";
        } else if (state.j === 7) {
          if (state.current_question_index === 0) {
            state.overview[state.current_question_index][2] = "current";
          } else {
            state.overview[state.current_question_index][1] = "current";
          }
        } else if (state.j === 30) {
          if (state.current_question_index === 0) {
            state.overview[state.current_question_index][3] = "current";
          } else if (state.current_question_index === 1) {
            state.overview[state.current_question_index][2] = "current";
          } else {
            state.overview[state.current_question_index][3] = "current";
          }
        }
      }
    },
    DEMOsetCurrentQuestionIndex: (state) => {
      if (state.j === 2) {
        state.overview[state.current_question_index][1] = "success";
      } else if (state.j === 7) {
        if (state.current_question_index === 0) {
          state.overview[state.current_question_index][2] = "success";
        } else {
          state.overview[state.current_question_index][1] = "success";
        }
      } else if (state.j === 30) {
        if (state.current_question_index === 0) {
          state.overview[state.current_question_index][3] = "success";
        } else if (state.current_question_index === 1) {
          state.overview[state.current_question_index][2] = "success";
        } else {
          state.overview[state.current_question_index][3] = "failure";
        }
      }
      state.current_question_index += 1;
    },
    DEMOsetJ: (state, action) => {
      state.j = action.payload;
      state.stage = 1;
      state.current_question_index = 0;
      if (demo_q[action.payload][state.client]) {
        state.questions = demo_q[action.payload][state.client];
      }

      if (action.payload < 3) {
        state.overview.forEach((element) => {
          for (let i = 1; i < 4; i++) {
            element[i] = "";
          }
        });
      } else if (action.payload === 7) {
        state.overview = [
          {
            key: "1",
            concept: client_concepts[state.client][0],
            acquisition: "01/01/2022 (J0)",
            1: "success",
            2: "",
            3: "",
          },
          {
            key: "2",
            concept: client_concepts[state.client][1],
            acquisition: "05/01/2022 (J+4)",
            1: "",
            2: "",
            3: "",
          },
          {
            key: "3",
            concept: client_concepts[state.client][2],
            acquisition: "07/01/2022 (J+6)",
            1: "",
            2: "",
            3: "",
          },
        ];
      } else if (action.payload === 30) {
        state.overview = [
          {
            key: "1",
            concept: client_concepts[state.client][0],
            acquisition: "01/01/2022 (J0)",
            1: "success",
            2: "success",
            3: "",
          },
          {
            key: "2",
            concept: client_concepts[state.client][1],
            acquisition: "05/01/2022 (J+4)",
            1: "success",
            2: "",
            3: "",
          },
          {
            key: "3",
            concept: client_concepts[state.client][2],
            acquisition: "07/01/2022 (J+6)",
            1: "failure",
            2: "failure",
            3: "",
          },
        ];
      }
    },
  },
});

export const {
  DEMOsetQuestions,
  DEMOsetStage,
  DEMOsetCurrentQuestionIndex,
  DEMOsetJ,
} = demoSlice.actions;
export default demoSlice.reducer;
