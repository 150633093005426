import { Select, Slider } from "antd";
import React, { useContext, useRef, useState, useEffect } from "react";
import {
  HiOutlineFastForward,
  HiOutlineRewind,
  HiOutlineVideoCamera,
  HiOutlineVolumeOff,
  HiOutlineVolumeUp,
} from "react-icons/hi";
import { ImLoop } from "react-icons/im";
import { BiPlayCircle, BiPauseCircle } from "react-icons/bi";
import ReactPlayer from "react-player";
import { ThemeContext } from "../../../../../ThemeProvider";
import { useSelector, useDispatch } from "react-redux";
import { XsetVideoPlayedID } from "../../../../../../appSlice";
const { Option } = Select;

const PlayerControls = ({
  playing,
  handlePlayPause,
  handleRewind,
  handleFastForward,
  muted,
  handleMute,
  handleVolumeChange,
  handlePlaybackRateChange,
  handleLoop,
  played,
  handleSeek,
  elapsedTime,
  totalDuration,
  duration,
  start_time_in_milliseconds,
  end_time_in_milliseconds,
  loop,
  answerClip,
  dataArrayIndex,
}) => {
  const video_played_id = useSelector(
    (state) => state.app.current.video_played_id
  );
  const startTimeInSeconds = start_time_in_milliseconds / 1000;
  const endTimeInSeconds = end_time_in_milliseconds / 1000;
  const startTimePercent = startTimeInSeconds / duration;
  const endTimePercent = endTimeInSeconds / duration;
  const formatter = () => elapsedTime;
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const marks = {
    [startTimePercent * 100]: {
      style: { width: 20, fontSize: 8, lineHeight: "10px" },
      label: t("start"),
    },
    [endTimePercent * 100]: {
      style: { width: 20, fontSize: 8, lineHeight: "10px" },
      label: t("end"),
    },
  };
  // console.log({
  //   startTimeInSeconds,
  //   endTimeInSeconds,
  //   startTimePercent,
  //   endTimePercent,
  //   marks,
  //   duration,
  // });
  return (
    <div
      style={{
        color: themeColors.primary.base,
      }}
      className={`bg-white rounded-b-lg opacity-90`}
    >
      <div className="w-full pt-1">
        <Slider
          value={played * 100}
          onChange={handleSeek}
          tipFormatter={formatter}
          marks={answerClip ? marks : {}}
          step={duration / 100000}
        />
      </div>

      <div
        id="controls"
        style={{
          color: themeColors.primary.base,
        }}
        className={`grid bg-gray-100 ${
          answerClip ? "grid-cols-4" : "grid-cols-3"
        } items-center -mt-1 py-1 rounded mb-2`}
      >
        {answerClip ? (
          <div
            id="loop-answer"
            style={{
              color: themeColors.primary.base,
            }}
            className={`px-1 flex flex-col items-center pt-1`}
          >
            {/*<Checkbox checked={loop} onChange={handleLoop} size="small" className="mx-auto"/>
            <div className="text-3xs leading-3 mt-1 text-center">{t("loop")}</div>*/}
            <ImLoop
              onClick={handleLoop}
              className={`cursor-pointer text-xl ${
                !loop && "opacity-40 hover:opacity-100"
              }`}
            />
            <div className="text-3xs">Loop</div>
          </div>
        ) : null}
        <div className="flex flex-col items-center">
          <div className="text-3xs">
            {elapsedTime}/{totalDuration}
          </div>
          <div
            style={{
              color: themeColors.primary.base,
            }}
            className={`flex items-center justify-center text-xl`}
          >
            <div
              className="flex flex-col items-center justify-center cursor-pointer"
              onClick={handleRewind}
            >
              <HiOutlineRewind />
            </div>
            <div
              className="flex flex-col items-center justify-center text-2xl cursor-pointer"
              onClick={() => handlePlayPause(dataArrayIndex)}
            >
              {playing && video_played_id === dataArrayIndex ? (
                <BiPauseCircle />
              ) : (
                <BiPlayCircle />
              )}
            </div>

            <div
              className="flex flex-col items-center justify-center cursor-pointer"
              onClick={handleFastForward}
            >
              <HiOutlineFastForward />
            </div>
          </div>
        </div>

        <div
          id="sound-controls"
          className="flex flex-col items-center justify-center ml-1"
        >
          {!muted && (
            <div className=" w-full h-5 pb-2">
              <Slider
                defaultValue={70}
                onChange={handleVolumeChange}
                style={{ padding: 0, color: "blue" }}
              />
            </div>
          )}
          <div
            className="items-center cursor-pointer text-lg"
            onClick={handleMute}
          >
            {muted ? <HiOutlineVolumeOff /> : <HiOutlineVolumeUp />}
          </div>
        </div>
        <div className="leading-3 flex flex-col items-center">
          <Select
            defaultValue="1x"
            style={{
              width: 60,
              color: themeColors.primary.base,
            }}
            onChange={handlePlaybackRateChange}
            size="small"
            bordered={false}
          >
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={0.9}
            >
              0.9x
            </Option>
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={1}
            >
              1x
            </Option>
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={1.1}
            >
              1.1x
            </Option>
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={1.2}
            >
              1.2x
            </Option>
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={1.5}
            >
              1.5x
            </Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

const AnswerVideo = ({
  url,
  start_time_in_milliseconds,
  end_time_in_milliseconds,
  dataArrayIndex,
}) => {
  const playerRef = useRef();
  const answerClip = start_time_in_milliseconds || end_time_in_milliseconds;

  const dispatch = useDispatch();

  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [played, setPlayed] = useState(0);
  const [ready, setReady] = useState(false);
  const [loop, setLoop] = useState(false);
  const handleLoop = () => {
    setLoop(!loop);
    console.log("loop", loop);
  };
  const handlePlayPause = (dataArrayIndex) => {
    if (!playing) {
      dispatch(XsetVideoPlayedID(dataArrayIndex));
    }
    setPlaying(!playing);
  };
  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
  };
  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 5);
  };
  const handleMute = () => {
    setMuted(!muted);
  };
  const handleVolumeChange = (e) => {
    setVolume(e / 100);
  };
  const handlePlaybackRateChange = (e) => {
    // console.log({ e });
    setPlaybackRate(e);
  };
  const handleProgress = (e) => {
    // console.log("progress", { e });
    const { played, playedSeconds } = e;
    setPlayed(played);
    if (
      playedSeconds >= end_time_in_milliseconds / 1000 &&
      playedSeconds < end_time_in_milliseconds / 1000 + 1.1
    ) {
      if (!loop) {
        setPlaying(false);
      } else {
        playerRef.current.seekTo(start_time_in_milliseconds / 1000);
      }
    }
    if (playedSeconds <= start_time_in_milliseconds / 1000) {
      //playerRef.current.seekTo(start_time_in_milliseconds / 1000);
    }
  };
  const handleSeek = (e) => {
    playerRef.current.seekTo(e / 100, "fraction");
  };

  const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";

  const duration =
    playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";

  const elapsedTime = format(currentTime);

  const totalDuration = format(duration);

  const handleReady = () => {
    // playerRef.current.seekTo(start_time_in_milliseconds / 1000);
    // setPlaying(false);
    setReady(true);
  };

  useEffect(() => {
    // console.log("ready");
    if (ready && playerRef && playerRef.current) {
      playerRef?.current?.seekTo(start_time_in_milliseconds / 1000);
    }
    // setPlaying(true);
  }, [ready, start_time_in_milliseconds]);

  const playerControlProps = {
    playing,
    handlePlayPause,
    handleRewind,
    handleFastForward,
    muted,
    handleMute,
    handleVolumeChange,
    handleLoop,
    loop,
    setLoop,
    volume,
    setPlaybackRate,
    handlePlaybackRateChange,
    played,
    handleSeek,
    elapsedTime,
    totalDuration,
    duration,
    start_time_in_milliseconds,
    end_time_in_milliseconds,
    answerClip,
    dataArrayIndex,
  };
  const { t } = useContext(ThemeContext);
  const video_played_id = useSelector(
    (state) => state.app.current.video_played_id
  );
  return (
    <div className=" pt-1">
      <div className="video-deco flex mb-3 items-center">
        <div className="text-base">
          <HiOutlineVideoCamera />
        </div>
        <div className="ml-1">{t("video")}</div>
      </div>
      <div
        className="video-wrapper"
        onClick={() => handlePlayPause(dataArrayIndex)}
      >
        <ReactPlayer
          url={url}
          ref={playerRef}
          playing={playing && video_played_id === dataArrayIndex}
          width="100%"
          height="100%"
          muted={muted}
          volume={volume}
          playbackRate={playbackRate}
          onProgress={handleProgress}
          progressInterval={1000}
          onReady={handleReady}
          // controls={true}
        />
      </div>
      <PlayerControls {...playerControlProps} />
    </div>
  );
};

export default AnswerVideo;
