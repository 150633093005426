import { ThemeContext } from "../../../ThemeProvider";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendQuestionToBob, XsetQuestionForLogBuffer } from "../../../../appSlice";

const Onboarding = () => {
  const onboarding_questions = useSelector(
    (state) => state.app.chat.onboarding_questions
  );
  const dispatch = useDispatch();
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);

  return (
    <div
      className={`flex h-full font-medium m-auto ${
        expanded_pane ? "w-2/3" : "w-5/6"
      }`}
    >
      <div className="flex flex-col m-auto gap-6">
        <div
          className={`flex flex-col text-justify gap-4 text-gray-400`}
        >
          <div>{t("onboarding-message-1")}</div>
          {onboarding_questions.length > 0 && <div>{t("onboarding-message-2")}</div>}
        </div>
        <div className="flex flex-col items-center gap-2 font-normal">
          {onboarding_questions.map((suggestion) => (
            <div
              className="text-white w-full text-center p-2 rounded-lg cursor-pointer"
              style={{
                backgroundColor: themeColors.primary.base,
              }}
              onClick={() => {
                dispatch(
                  XsetQuestionForLogBuffer({
                    event_type: "askQuestionToBob",
                    input: suggestion,
                    origin: "onboarding",
                    from: null,
                    userInput: null,
                  })
                );
                dispatch(sendQuestionToBob(suggestion));
              }}
            >
              {suggestion}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
