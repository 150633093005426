import { forwardRef } from "react";
import MarkDownRender from "../../../../../MarkDown/MarkDownRender";
import { useSelector } from "react-redux";

const ChatMessageItemUser = forwardRef(
  ({ chat, dataArrayIndex }, olderMessageScrollRef) => {
    const themeColors = useSelector(
      (state) => state.app.theme.color.theme_colors
    );
    const older_message_index = useSelector(
      (state) => state.app.chat.older_message_index
    );
    return (
      <div className="flex w-full" ref={olderMessageScrollRef}>
        <div className="w-1/6" />
        <div className="flex flex-col items-end w-5/6">
          <div
            style={{
              backgroundColor: themeColors.primary.base,
            }}
            className="flex flex-col ml-6 rounded-lg rounded-tr-sm p-3 text-white whitespace-normal"
          >
            <MarkDownRender compact noEllipsisFilter source={chat.text} />
          </div>
        </div>
      </div>
    );
  }
);

export default ChatMessageItemUser;
