import MarkDownRender from "../../MarkDown/MarkDownRender";
import { useContext, useEffect } from "react";
import { Spin } from "antd";
import { useState } from "react";
import { PANES } from "../../../constants/constants";
import PaneContent from "../components/PaneContent";
import { ThemeContext } from "../../ThemeProvider";
import { ReloadOutlined, VideoCameraFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { BiHourglass } from "react-icons/bi";
import { HiOutlineMailOpen, HiOutlineMail } from "react-icons/hi";
import { RiChat3Fill } from "react-icons/ri";
import {
  XgetTeacherConversations,
  XsetActivePane,
  XgetUserStudentMetrics,
  XsetCurrentTeacherConversation,
} from "../../../appSlice";


const ConversationWithTeacherCard = ({ conversation }) => {
  const dispatch = useDispatch();
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  if (
    conversation.lastMessage.external_id ===
    conversation.firstMessage.external_id
  ) {
    return (
      <div className="flex flex-1 justify-start items-center px-3 py-2 text-gray-400 gap-3 bg-gray-100 rounded-md">
        <div className="text-lg">
          <BiHourglass />
        </div>
        <div className="">Q: {conversation?.firstMessage?.content}</div>
      </div>
    );
  }
  return (
    <div
      className={`rounded-md border flex items-center p-3 cursor-pointer gap-3 break-words`}
      style={
        conversation.nb_unread_messages
          ? { borderColor: themeColors.primary.base }
          : {}
      }
      onClick={() => {
        dispatch(
          XsetCurrentTeacherConversation({
            current_conversation: conversation,
            external_conversation_id: conversation.external_conversation_id,
          })
        );
      }}
    >
      {conversation.nb_unread_messages ? (
        <div className="mb-auto relative">
          <div
            className="absolute w-2 h-2 rounded-full"
            style={{ backgroundColor: themeColors.primary.base }}
          ></div>
          <HiOutlineMail className="text-gray-400 text-xl" />
        </div>
      ) : (
        <HiOutlineMailOpen className="text-gray-400 text-xl mb-auto" />
      )}

      <div className="flex flex-col gap-1 w-full text-gray-900 font-medium">
        Q: {conversation?.firstMessage?.content}
        {conversation.nb_messages_total > 2 && (
          <div className="text-gray-500 font-medium text-3xs">
            +
            {conversation.nb_messages_total -
              2 +
              (conversation.nb_messages_total > 3 ? " messages" : " message")}
          </div>
        )}
        <div className="flex whitespace-pre-line gap-1">
          <div className="ml-2">
            {conversation?.lastMessage?.msg_type === "question"
              ? t("you")
              : t("answer")}
          </div>
          <div className="text-gray-700 font-normal line-clamp-3">
            <MarkDownRender source={conversation?.lastMessage?.content || ""} compact/>
          </div>
        </div>
      </div>
    </div>
  );
};

const TeacherAnswerPane = () => {
  const dispatch = useDispatch();
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const conversations = useSelector((state) => state.app.teacher.conversations);
  const loading = useSelector((state) => state.app.teacher.loading);
  const dev_mode = useSelector((state) => state.app.general.dev_mode);
  const external_client_id = useSelector(
    (state) => state.app.id.external_client
  );
  const [isSpinning, setSpin] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleReload = () => {
    dispatch(XgetUserStudentMetrics());
    setSpin(true);
    setTimeout(() => setSpin(false), 2000);
  };

  useEffect(() => {
    if (conversations.length === 0) {
      dispatch(XgetTeacherConversations());
    }
  }, []);

  const handleZoomButton = () => {
    window.open("https://asu.zoom.us/j/81868714615", "_blank");
  };

  const ZoomLinkForASU = () => {
    if (external_client_id === "wI8uqv3BcEh2r5") {
      return (
        <div>
          or connect to Zoom center{" "}
          <VideoCameraFilled
            style={{
              color: themeColors.primary.base,
            }}
            className={`mx-1 my-auto text-xl cursor-pointer`}
            onClick={() => {
              handleZoomButton();
            }}
          />{" "}
          during opening hours (11am-5pm Monday-Friday)
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <PaneContent>
      <div className="space-y-2 p-2 mb-2 h-full">
        {dev_mode ? (
          <div
            style={{
              backgroundColor: hovered
                ? themeColors.primary.strong
                : themeColors.primary.base,
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="w-1/3 mx-auto flex items-center justify-center rounded h-6 text-white px-3 cursor-pointer font-medium"
            onClick={() => handleReload()}
          >
            {t("refresh")}
            <Spin
              className="flex"
              indicator={
                <ReloadOutlined
                  spin={isSpinning ? true : false}
                  style={{ fontSize: "14px" }}
                  className={`ml-3 text-white items-center justify-center`}
                />
              }
            />
          </div>
        ) : (
          <div className="items-center text-center flex flex-col px-3 text-gray-600">
            {t("teacher-answer-description")}
            <div className="flex items-center text-center">
              {t("teacher-answer-new-question") + t("ask-bob")}
              <RiChat3Fill
                style={{ color: themeColors.primary.base }}
                className={`ml-1 cursor-pointer text-2xl`}
                onClick={() => {
                  dispatch(XsetActivePane(PANES.CHAT));
                }}
              />
            </div>
            <ZoomLinkForASU />
          </div>
        )}
        {!loading ? (
          conversations?.slice(0).map((conversation, i) => {
            return (
              <div key={i}>
                <ConversationWithTeacherCard conversation={conversation} />
              </div>
            );
          })
        ) : (
          <div className="flex items-center p-4">
            <Spin className="m-auto" />
          </div>
        )}
      </div>
    </PaneContent>
  );
};

export default TeacherAnswerPane;
