const resources = {
  en: {
    translation: {
      // chat
      "bot-name":"Bob",
      "onboarding-message-1":
        "👋 Hi, I am Bob. I'm here to answer all your questions about the course 📖",
      "onboarding-message-2":
        "Let's try, ask your first question or click on a suggestion bellow👇",
      "first-message": "Hi! I'm here to help you! 😄",
      "question-sent": "Your question has been sent! 😄",
      "question-edit-sent": "Edit and send your question to a teacher:",
      "tips-ask-bob":
        "Answering course questions is my purpose, you can query me and select a question from the suggestions.",
      no: "No",
      yes: "Yes",
      or: 'or',
      "question-confirm": "Would you like to ask a teacher about this?",
      "see-less": "see less",
      "see-more": "see more",
      expand: "Expand",
      contract: "Contract to short answer",
      "explore-source": "Explore the source",
      // hints:
      "see-more-hints": "(see more)",
      // video
      video: "Video",
      loop: "Loop",
      speed: "speed",
      start: "Start",
      end: "End",
      // app
      "conversation-starter": "👋 Hello! Ready to learn?",
      "pane-header-all-widgets": "Dashboard",
      "pane-header-chat": "Ask Bob!",
      "pane-header-chat-ASU": "ASU Tutor Bot",
      "pane-header-teacher-questions": "Teacher Answers",
      "pane-header-memory-anchoring": "Knowledge Anchoring",
      "pane-header-settings": "Settings",
      "pane-header-info": "Info",
      "ask-bob": "Ask Bob!",
      "ask-ASU": "Ask ASU Tutor Bot",
      "ask-teacher-directly": "Ask your question directly to a Professor",
      "your-question-for-teacher": "Your question to teachers:",
      //tooltip
      "tooltip-chat": "Ask me some questions",
      "tooltip-teacher": "Access to the teachers' answers",
      "tooltip-KA": "Practice with the Knowledge Anchoring",
      "tooltip-AL": "Test yourself with Adaptive Learning",
      "tooltip-info": "Hi, I'm Bob...",
      // teacher answers
      "mark-unread": "mark as unread",
      "teacher-answer-new-question": "Do you have new questions ? ",
      "teacher-answer-description":
        "Find here your conversations with teachers",
      refresh: "Refresh",
      "respond-to-teacher": "Respond to teachers",
      you: "You: ",
      answer: "Answer: ",
      reply: "Reply",
      "conversation-closed":
        "This conversation has been closed by your teacher, you can Reopen it by clicking below",
      // memory anchoring
      "accept-tcu-1": "I have read and accept the ",
      "accept-tcu-2": "terms and conditions",
      "memory-anchoring-3-questions": "Review and memorize with some questions",
      "memory-anchoring-lets-go": "Let's go!",
      "memory-anchoring-user-answers-I-knew-it": "I knew it!",
      "memory-anchoring-user-answers-I-am-learning-it": "Now I know!",
      "memory-anchoring-congratulations": "🎉 Congratulations!",
      "memory-anchoring-come-again": "Come again tomorrow and keep learning!",
      "memory-anchoring-come-again-content": "Will you do better than today?",
      "demo-description":
        "Anchoring tests are sent to the student at +2, +7, +30 days after each successful test. In case of failure, the cycle is reset. After 3 failures, the teacher is alerted.",
        "1st":"1st",
        "2nd": "2nd",
        "3rd":"3rd",
        "4th":"4th",
        "learning-date":"Learning date of the concept",
      // dashboard
      "dashboard-memory-anchoring-title": "Knowledge Anchoring",
      "dashboard-memory-anchoring-content":
        "Test yourself with 3 new questions.\n\nGo check them out!",
      "dashboard-teacher-questions-title": "Teacher Answers",
      "dashboard-teacher-questions-content-has-answers":
        "You have new answers!",
      "dashboard-teacher-questions-content-has-no-answers":
        "Chat and ask teachers!",
      "dashboard-chat-title": "Ask Bob!",
      "dashboard-chat-title-asu": "ASU Tutor Bot",
      "dashboard-chat-content": "Bob is here to help! Ask away!",
      "dashboard-chat-content-ASU": "ASU tutor Bot is here to help! Ask away!",
      // settings
      "bob-language": "Bob Language:",
      "bob-language-en": "English",
      "bob-language-fr": "French",
      "settings-position-label": "Position:",
      "settings-position-right": "Right",
      "settings-position-left": "Left",
      "settings-sidebar-position-label": "Sidebar Position:",
      "settings-sidebar-position-center": "Center",
      "settings-sidebar-position-bottom": "Bottom",
      theme: "Theme:",
      "theme-default": "Bob",
      "theme-asu": "ASU",
      "coming-soon": "Coming soon!",
      "welcome-knowledge-anchoring-page":
        "Welcome to the Knowledge Anchoring ! 😄",
      "you-dont-have-knowledge-anchoring-for-today":
        "There isn't any question for this time.",
      "memory-anchoring-come-again-tomorow": "Come again tomorrow !",
      "memory-anchoring-description-1":
        "This method allows you to memorize what you learn more efficiently and durably.",
      "memory-anchoring-description-2":
        "Sign up to receive 3 questions a day that will stimulate your mind and improve your knowledge retention.",
      "enable-email-reminders": "Enable email reminders",
      "sign-in": "Sign In",
      "is-this-useful": "Is this answer useful?",
      "similar-question": "Here are some similar questions:",
      "similar-question-original-question":
        "Here are some similar questions for your search ",
      "bob-found-answer": "I hope this answers your question ! 😄",
      "bob-found-similar-question":
        "Here is the answer I found for the following similar question",
    },
  },
  fr: {
    translation: {
      // chat
      "bot-name":"Bob",
      "first-message": "Salut! Je suis ici pour vous aider! 😄",
      "onboarding-message-1":
        "Je m'appelle Bob, je suis un assistant virtuel. Je suis ici pour vous aider.",
      "onboarding-message-2":
        "Posez moi une question ou cliquez sur une des suggestions suivantes:",
      "question-sent": "Votre question a bien été envoyée! 😄",
      "question-edit-sent": "Editez votre question au professeur :",
      "tips-ask-bob":
        "Répondre aux questions de cours est ma spécialité, tu peux m'interroger et sélectionner une question parmi les suggestions.",
      no: "Non",
      yes: "Oui",
      or: 'ou',
      "question-confirm": "Voulez-vous demander à un professeur?",
      "see-less": "voir moins",
      "see-more": "voir plus",
      expand: "Agrandir",
      contract: "Réduire (réponse raccourcie)",
      "explore-source": "Visiter la source",
      // hints:
      "see-more-hints": "(voir plus)",
      // video
      video: "Vidéo",
      loop: "Boucle",
      speed: "vitesse",
      start: "Début",
      end: "Fin",
      // App
      "conversation-starter": "👋 Salut! Prêt pour aujourd'hui?",
      "pane-header-all-widgets": "Dashboard",
      "pane-header-chat": "Demande à Bob!",
      "pane-header-chat-ASU": "Demande au tuteur ASU!",
      "pane-header-teacher-questions": "Réponses du Professeur",
      "pane-header-memory-anchoring": "Mémorisation",
      "pane-header-settings": "Paramètres",
      "pane-header-info": "Info",
      "ask-bob": "Demande à Bob!",
      "ask-ASU": "Demande au tuteur ASU!",
      "ask-teacher-directly": "Pose ta question directement à un Professeur",
      "your-question-for-teacher": "Votre question pour un professeur:",
      //tooltip
      "tooltip-chat": "Pose moi des question",
      "tooltip-teacher": "Accède aux réponses des professeurs",
      "tooltip-KA": "Entraine toi avec l'ancrage mémoriel",
      "tooltip-AL": "Teste toi avec l'apprentissage adaptatif",
      "tooltip-info": "Salut, moi c'est Bob...",
      // teacher answers
      "mark-unread": "marquer comme non-lu",
      "teacher-answer-new-question": "Tu as une nouvelle question ? ",
      "teacher-answer-description":
        "Retrouve ici tes conversations avec les professeurs",
      refresh: "Actualiser",
      "respond-to-teacher": "Échange avec les professeurs",
      you: "Vous: ",
      answer: "Réponse: ",
      reply: "Répondre",
      "conversation-closed":
        "Cette conversation a été close par le professeur, tu peux la rouvrir en cliquant ci-dessous",
      // memory anchoring
      "accept-tcu-1": "J'ai pris connaissance et accepte les ",
      "accept-tcu-2":
        "Conditions Générales d'Utilisation et Informations relatives aux données personnelles",
      "memory-anchoring-3-questions":
        "Quelques questions pour entraîner ta mémoire",
      "memory-anchoring-lets-go": "C'est parti!",
      "memory-anchoring-user-answers-I-knew-it": "Je le savais!",
      "memory-anchoring-user-answers-I-am-learning-it": "Je l'apprends!",
      "memory-anchoring-congratulations": "🎉 Bravo !",
      "memory-anchoring-come-again":
        "Reviens demain pour continuer l’entraînement !",
      "memory-anchoring-come-again-content":
        "Feras-tu aussi bien qu’aujourd’hui ? Plus qu’hier et moins que demain ?",
      "demo-description":
        "Les tests d’ancrage sont envoyés à l’élève à +2, +7, +30 jours après chaque test réussi. En cas d’échec, le cycle est réinitialisé. Après 3 échecs, le prof est alerté.",
        "1st":"1er",
        "2nd": "2nd",
        "3rd":"3ème",
        "4th":"4ème",
        "learning-date":"Date d'acquisition du concept",
      // dashboard
      "dashboard-memory-anchoring-title": "Ancrage Mémoriel",
      "dashboard-memory-anchoring-content":
        "Vérifie tes connaissances avec 3 nouvelles questions!",
      "dashboard-teacher-questions-title": "Réponses Professeurs",
      "dashboard-teacher-questions-content-has-answers":
        "Tu as de nouvelles réponses!",
      "dashboard-teacher-questions-content-has-no-answers":
        "Chat et pose tes questions aux professeurs!",
      "dashboard-chat-title": "Demande à Bob!",
      "dashboard-chat-title-asu": "Demande au tuteur ASU!",
      "dashboard-chat-content":
        "Bob est là pour t'aider! Pose lui une question!",
      "dashboard-chat-content-ASU":
        "le tuteur ASU est ici pour t'aider! Pose ta question!",
      // settings
      "bob-language": "Langue Bob:",
      "bob-language-en": "Anglais",
      "bob-language-fr": "Français",
      "settings-position-label": "Position de Bob:",
      "settings-position-right": "Droite",
      "settings-position-left": "Gauche",
      "settings-sidebar-position-label": "Position de la Sidebar:",
      "settings-sidebar-position-center": "Centre",
      "settings-sidebar-position-bottom": "Bas",
      theme: "Thème:",
      "theme-default": "Bob",
      "theme-asu": "ASU",
      "coming-soon": "Bientôt disponible!",
      "welcome-knowledge-anchoring-page":
        "Bienvenue à l'Ancrage de Connaissances ! 😄",
      "you-dont-have-knowledge-anchoring-for-today":
        "Il n'y a pas question pour cette fois.",
      "memory-anchoring-come-again-tomorow": "Reviens demain !",
      "memory-anchoring-description-1":
        "Cette méthode permet de mémoriser plus efficacement et durablement ce que tu apprends. ",
      "memory-anchoring-description-2":
        "Inscris-toi pour recevoir jusqu'à 3 questions par jour qui vont stimuler ton esprit et améliorer la rétention de tes connaissances dans le temps.",
      "enable-email-reminders": "Activer les rappels par email",
      "sign-in": "S'inscrire",
      "is-this-useful": "Cette réponse est-elle utile ?",
      "similar-question": "Voici une liste des questions similaires:",
      "similar-question-original-question":
        "Voici une liste de questions similaires pour votre recherche",
      "bob-found-answer": "J'espère que cela répond à la question:",
      "bob-found-similar-question":
        "Voici la question que j'ai sélectionnée et sa réponse associée : ",
    },
  },
};

export default resources;
