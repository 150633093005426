import { useRef } from "react";
import PaneContent from "../components/PaneContent";
import InputAndHints from "./components/InputAndHints/InputAndHints";
import Onboarding from "./components/Onboarding";
import ChatMessages from "./components/ChatMessages/ChatMessages";
import { useSelector } from "react-redux";
import HintsV2 from "./components/InputAndHints/components/HintsV2";

const ChatPane = () => {
  const chatInputRef = useRef(null);
  const focusOnChatInput = () => {
    chatInputRef.current.focus();
  };

  const chat_data_array = useSelector(
    (state) => state.app.chat.chat_data_array
  );

  const ab_test_send_blocked = useSelector(
    (state) => state.app.ab_test.send_blocked
  );

  const bob_input_value = useSelector(
    (state) => state.app.chat.bob_input_value
  );

  return (
    <>
      <PaneContent>
        {chat_data_array.length > 0 ? (
          <ChatMessages focusOnChatInput={focusOnChatInput} />
        ) : (
          <Onboarding />
        )}
        {bob_input_value.length > 1 && ab_test_send_blocked && <HintsV2 />}
      </PaneContent>
      <InputAndHints chatInputRef={chatInputRef} />
    </>
  );
};

export default ChatPane;
